<!-- 黑名单 -->
<template>
    <descriptions-with-head title="黑名单">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                label="业务对象"
                prop="businessObjectArr">
                <business-object-select
                    v-model="form.businessObjectArr"
                    @change="handleChange" />
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import BusinessObjectSelect from '@weights/GbCloud/BusinessObjectSelect'
import { FormModel as AFormModel } from 'ant-design-vue'

export default {
    name: 'BlackList',
    components: {
        DescriptionsWithHead,
        BusinessObjectSelect,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
    // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            form: {
                businessObjectArr: [],
                blackObjectVOS: []
            },
            rules: {
                businessObjectArr: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        modelInfo: {
            handler(val) {
                if (!val.blackObjectVOS) return
                const { blackObjectVOS } = val
                this.form.blackObjectVOS = blackObjectVOS
                blackObjectVOS.map(item => {
                    this.form.businessObjectArr.push(item.objectCode)
                })
            },
            immediate: true
        }
    },
    methods: {
        handleChange(res) {
            this.form.blackObjectVOS = res
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let resultObj = JSON.parse(JSON.stringify(this.form))
                    delete resultObj.businessObjectArr
                    res = resultObj
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
