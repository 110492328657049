import getDataCheckModelListService from '@service/ruleEngine/DataCheckSetting/getDataCheckModelListService'
import { DATA_CHECK_MODEL, GRADE_MODEL, RISK_ASSESS_MODEL, RISK_CLASS_MODEL, SMART_ASSESS_MODEL, RULE_SET_MODEL } from '@constant/enum'
import getGradeModelListService from '@service/ruleEngine/GradeModel/getGradeModelListService'
import getRiskAssessmentModelListService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentModelListService'
import getRiskClassListService from '@service/ruleEngine/riskClass/getRiskClassListService'
import getSmartAssessModelListService from '@service/ruleEngine/smartAssessModel/getSmartAssessModelListService'
import getRuleSetListService from '@service/ruleEngine/ruleSet/getRuleListService'

export default [
    {
        key: '1',
        label: '数据核验模型审核',
        getListService: getDataCheckModelListService,
        model: DATA_CHECK_MODEL
    },
    {
        key: '2',
        label: '评分模型审核',
        getListService: getGradeModelListService,
        model: GRADE_MODEL
    },
    {
        key: '3',
        label: '风险评估模型审核',
        getListService: getRiskAssessmentModelListService,
        model: RISK_ASSESS_MODEL
    },
    {
        key: '4',
        label: '风险等级模型审核',
        getListService: getRiskClassListService,
        model: RISK_CLASS_MODEL
    },
    {
        key: '5',
        label: '智能评估模型审核',
        getListService: getSmartAssessModelListService,
        model: SMART_ASSESS_MODEL
    },
    {
        key: '6',
        label: '规则集审核',
        getListService: getRuleSetListService,
        model: RULE_SET_MODEL
    }
]
