<!-- 审批及日志-规则审核 -->
<template>
    <list-page-layout>
        <div slot="header">
            <container-panel>
                <radio-group-panel
                    :radio-data="tabsData"
                    :default-active-key="defaultActiveKey"
                    @change="handleRadioGroupChange"
                />
            </container-panel>
            <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        </div>
        <container-panel :no-card-body-padding-top="true">
            <template slot="title">规则审核列表</template>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <!-- 表格的序号递增 -->
                    <template slot="sort" slot-scope="text, record,index">{{ index + 1 }}</template>
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="APPROVE_MANAGE_RULE_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON"
                            type="link"
                            @click="handleInfo(action)"
                        >
                            查看
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import SearchPanel from './SearchPanel'

import { APPROVE_MANAGE_RULE_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON } from '@constant/authEnum/approve'
import {
    DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
} from '@constant/enum'

import getDataCheckModelListService from '@service/ruleEngine/DataCheckSetting/getDataCheckModelListService'
import { filterParams } from '@utils/search'
import { defaultColumns, riskClassColumns } from './columns'
import tabsData from './tabsData'
import initTable from '@mixins/initTable'

export default {
    name: 'RuleApproveList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        RadioGroupPanel,
        AButton
    },
    mixins: [initTable],
    data() {
        return {
            APPROVE_MANAGE_RULE_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON, // 查看
            dataList: [],
            loading: false,
            visible: false,
            tabsData,
            defaultActiveKey: '1',
            getListService: getDataCheckModelListService
        }
    },
    computed: {
        // 是否是风险等级模型
        isRiskClassModel() {
            const { defaultActiveKey } = this
            return defaultActiveKey === '4'
        },
        columns() {
            const { isRiskClassModel } = this
            let columns = []
            if (isRiskClassModel) {
                columns = riskClassColumns
            } else {
                columns = defaultColumns
            }
            return columns
        }
    },
    methods: {
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params, getListService } = this
                const param = {
                    current,
                    size: limit,
                    ...params,
                    auditState: DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
                }
                const { data } = await getListService(filterParams(param))
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 查看跳转
         * */
        handleInfo({ id }) {
            const { tabsData, defaultActiveKey } = this
            // model 不同模型做区分使用
            const model = tabsData.find(item => item.key === defaultActiveKey).model
            this.$router.push({
                name: 'RuleApproveListInfo',
                query: {
                    id,
                    model
                }
            })
        },
        handleRadioGroupChange(e) {
            this.defaultActiveKey = e.target.value
            this.current = 1
            const { tabsData, defaultActiveKey } = this
            this.getListService = tabsData.find(item => item.key === defaultActiveKey).getListService
            this.getList()
        }
    }
}
</script>
<style lang="scss">
</style>
