<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col :span="6" label="参数名称" prop="name">
            <a-input
                v-model="form.name"
                slot="formElements"
                placeholder="请输入参数名称" />
        </form-col>
        <form-col :span="6" label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId"
                                       slot="formElements" />
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import { filterParams } from '@utils/search'

export default {
    name: 'PolicyListSearchPanel',
    components: {
        AInput,
        FormCol,
        SearchPanel,
        ProductCategoryCascader
    },
    data() {
        return {
            form: {
                name: '',
                riskCategoryId: ''
            }
        }
    },
    methods: {
    /**
     * 响应搜索事件
     */
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        /**
     * 响应刷新事件
     */
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style>

</style>
