<!-- 规则审核表单 -->
<template>
    <a-form-model
        ref="ruleForm"
        class="rule-approve-form-panel"
        layout="horizontal"
        :label-col="{ span: 5}"
        :wrapper-col="{ span: 16}"
        :model="form"
        :rules="rules"
    >
        <a-form-model-item label="审核意见" prop="remark">
            <a-input
                v-model="form.remark"
                type="textarea"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="请输入请输入审核意见"
                :rows="6" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { FormModel as AFormModel, Input as AInput } from 'ant-design-vue'

import enumOperate from '@mixins/enumOperate'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'RuleApproveModalFormPanel',
    components: {
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    mixins: [enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        auditService: {
            type: Function,
            default() {
                return () => {
                }
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                remark: ''
            },
            rules: {
                remark: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入审核意见'
                    }
                ]
            }
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        }
    },
    watch: {},
    methods: {
        handleSubmit(...arg) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService(...arg)
                }
            })
        },
        async submitService(agree, loadingName) {
            const { auditService, form, id } = this
            const params = {
                ...form,
                agree,
                id
            }
            try {
                this.$emit(loadingName, true)
                await auditService(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit(loadingName, false)
            }
        }
    }
}
</script>

<style lang='scss'>
</style>
