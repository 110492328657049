<template>
    <page-layout :show-loading="true" :loading="loading">
        <container-panel class="data-check-info-page-panel">
            <commodity-head slot="title" :info="info" />
            <div slot="button">
                <a-space v-if="showEditAndDeleteButton">
                    <a-button
                        v-auth="COMMODITY_MANAGE_DELETE_COMMODITY_BUTTON"
                        @click="handleDelete">
                        删除
                    </a-button>
                    <a-button
                        v-auth="COMMODITY_MANAGE_EDIT_COMMODITY_BUTTON"
                        type="primary"
                        @click="handleEdit">
                        编辑
                    </a-button>
                </a-space>
                <div class="data-check-info-page-panel__head-right-desc">
                    <span>状态：</span>{{ AUDIT_STATE_MAP[info.auditState] }}
                </div>
            </div>
        </container-panel>
        <audit-state
            :info="info" />
        <basic-info :info="info" />
        <risk-control-info :info="info" />
    </page-layout>
</template>

<script>
import { Button as AButton, Space as ASpace } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import ContainerPanel from '@components/ContainerPanel'
import AuditState from '@weights/AuditState'

import {
    COMMODITY_MANAGE_DELETE_COMMODITY_BUTTON,
    COMMODITY_MANAGE_EDIT_COMMODITY_BUTTON
} from '@constant/authEnum/commodity'
import { AUDIT_STATE_MAP, DATA_CHECK_MODEL_AUDIT_STATE_REFUSE } from '@constant/enum'
import { ERROR_COMMIT_DELETE } from '@constant/error'
import enumOperate from '@mixins/enumOperate'

import getCommodityInfoService from '@service/commodityManage/commodityConfig/getCommodityInfoService'
import deleteCommodityService from '@service/commodityManage/commodityConfig/deleteCommodityService'

export default {
    name: 'CommodityInfo',
    components: {
        AuditState,
        AButton,
        ASpace,
        PageLayout,
        ContainerPanel,
        BasicInfo: () => import('@weights/CommodityInfo/BasicInfo'),
        RiskControlInfo: () => import('@weights/CommodityInfo/RiskControlInfo'),
        CommodityHead: () => import('@weights/CommodityInfo/CommodityHead')
    },
    mixins: [enumOperate],
    data() {
        return {
            COMMODITY_MANAGE_DELETE_COMMODITY_BUTTON,
            COMMODITY_MANAGE_EDIT_COMMODITY_BUTTON,
            info: {},
            AUDIT_STATE_MAP,
            loading: false
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id
        },
        // 只有审核未通过的情况下，才可以编辑，或删除商品
        showEditAndDeleteButton() {
            const { auditState } = this.info
            return auditState === DATA_CHECK_MODEL_AUDIT_STATE_REFUSE
        }
    },
    watch: {},
    created() {
        this.getInfo()
    },
    methods: {
        handleSuccess() {
            this.getInfo()
        },
        handleDelete() {
            this.$confirm({
                title: '删除当前模型，将删除当前模型的所有信息，确认删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService()
                },
                onCancel() {
                }
            })
        },
        handleEdit() {
            this.$router.push({
                name: 'AddCommodity',
                query: {
                    id: this.queryId
                }
            })
        },
        async deleteService() {
            try {
                const { queryId } = this
                await deleteCommodityService(queryId)
                this.$message.success(ERROR_COMMIT_DELETE)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
     * 获取商品详情
     * */
        async getInfo() {
            try {
                this.loading = true
                const { queryId } = this
                this.info = await getCommodityInfoService({
                    id: queryId
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.data-check-info-page-panel {
  .ant-card-body {
    padding: 0 0 0 20px;
  }

  .ant-descriptions {
    border-bottom: 1px dashed $border-color;
  }

  .ant-card-head-title {
    padding: 16px 0 0;
  }

  &__head-right-desc {
    margin-top: 10px;
    text-align: right;

    span {
      color: #999
    }
  }
}
</style>
