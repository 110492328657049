<template>
    <a-form-model
        ref="form"
        class="fill-commodity-base-info"
        layout="horizontal"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="商品名称" prop="name">
            <a-input v-model.trim="form.name" type="text" :max-length="INPUT_MAX_LENGTH" placeholder="请输入商品名称" />
        </a-form-model-item>
        <a-form-model-item label="商品描述">
            <a-input v-model.trim="form.description" type="textarea" :max-length="INPUT_MAX_LENGTH" placeholder="请输入内容" />
        </a-form-model-item>
        <a-form-model-item
            label="产品分类"
            prop="riskCategoryId"
            :rules="[{
                required: true,
                message: '请选择',
                trigger: 'blur',
            },{validator:validateCategory}]">
            <product-category-cascader v-model="form.riskCategoryId" />
        </a-form-model-item>
        <template v-if="productParamsData.length">
            <a-form-model-item
                v-for="(item,index) in productParamsData"
                :key="item.productSpecId"
                :label="item.productSpecName"
                :prop="`productParamsData[${index}].attributeId`"
                :rules="{
                    required: item.isRequired,
                    message: '请选择',
                    trigger: 'blur',
                }">
                <a-select v-model="item.attributeId" :allow-clear="true">
                    <a-select-option
                        v-for="type in item.productSpecValueDTOList"
                        :key="type.attributeValueId"
                        :value="type.attributeValueId">
                        {{ type.attributeValue }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </template>
        <a-form-model-item label="订单来源" prop="orderSource">
            <order-source-select v-model="form.orderSource" />
        </a-form-model-item>
        <a-form-model-item label="地区范围" prop="area">
            <area-cascader
                v-model="form.area"
                ref="areaRef"
                @area-finish="areaFinish" />
            <a-button
                type="primary"
                style="margin-left: 5px; position: absolute; top: -6px"
                @click="handleAddArea">
                添加
            </a-button>
        </a-form-model-item>

        <a-form-model-item
            :wrapper-col="{span: 10, offset: 8}">
            <div class="fill-commodity-base-info__area">
                <a-tag
                    v-for="(item,index) in selectAreaData"
                    :key="item.code"
                    closable
                    @close="(e)=>handleDeleteArea(e,index,item)">
                    {{ item.name }}
                </a-tag>
            </div>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput, Select as ASelect, Button as AButton, Tag as ATag
} from 'ant-design-vue'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import getProductParamsByProductCategoryService
from '@service/commodityManage/commodityConfig/getProductParamsByProductCategoryService'
import InitForm from '@mixins/initForm'
import { INPUT_MAX_LENGTH, COUNTRY_CODE } from '@constant/Const'
import { getChildIds } from '@utils/toTree'

export default {
    name: 'FillInBasicInfo',
    components: {
        ATag,
        AButton,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ProductCategoryCascader,
        ASelect,
        ASelectOption: ASelect.Option,
        AreaCascader: () => import('@weights/AreaCascader'),
        OrderSourceSelect: () => import('@components/select/OrderSourceSelect')
    },
    mixins: [InitForm],
    props: {
        commodityInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            id: 1,
            labelCol: {
                span: 4,
                offset: 4
            },
            wrapperCol: {
                span: 10
            },
            productParamsData: [], // 动态的产品参数列表
            form: {
                name: '',
                area: [],
                riskCategoryId: '',
                description: '',
                orderSource: '', // 订单来源
                productParamsData: [],
                productAttributeId: [], // 产品参数选择的ids
                areaCode: []
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入商品名称',
                        trigger: 'blur'
                    }
                ],
                riskCategoryId: [
                    {
                        required: true,
                        message: '请选择产品分类',
                        trigger: 'blur'
                    }
                ],
                area: [
                    {
                        required: true,
                        message: '请选择地区',
                        trigger: 'blur'
                    }
                ],
                orderSource: [
                    {
                        required: true,
                        message: '请选择订单来源',
                        trigger: 'blur'
                    }
                ]
            },
            productParamsInitObj: {}, // 动态参数回显使用
            selectAreaData: [],
            areaMessage: '地区选择错误',
            areaMap: null
        }
    },
    computed: {
        params() {
            const { form, selectAreaData } = this
            let params = JSON.parse(JSON.stringify(form))
            params.productAttributeId = []
            params.productSpecAttributeSupplementId = []
            params.productParamsData.forEach(item => {
                const { isRequired, attributeId } = item
                // 如果此字段为必填
                if (isRequired) {
                    params.productAttributeId.push(attributeId)
                } else {
                    attributeId && params.productSpecAttributeSupplementId.push(attributeId)
                }
            })
            params.areaCode = selectAreaData.map(item => item.value)
            delete params.area
            delete params.productParamsData
            return params
        }
    },
    watch: {
        'form.riskCategoryId': {
            handler(riskCategoryId) {
                if (!riskCategoryId) return
                this.getProductParamsByProductCategory(riskCategoryId)
            }
        },
        commodityInfo() {
            if (this.areaMap) {
                this.initCommodity()
            }
        }
    },
    methods: {
        validateCategory(rule, value, callback) {
            const len = value.length
            if (len >= 2) {
                callback()
            } else {
                const errorMsg = `至少选择到二级分类`
                callback(new Error(errorMsg))
            }
        },
        areaFinish(data, map) {
            this.areaMap = map
            this.initCommodity()
        },
        // 回显详情页
        initCommodity() {
            const { commodityInfo } = this
            if (commodityInfo.id) {
                const { form } = this
                const { specDTOList } = commodityInfo
                const { getName } = this.$refs.areaRef
                this.initForm(commodityInfo, form)
                form.areaCode && form.areaCode.map(item => {
                    const obj = {
                        name: getName(item).join(''),
                        value: item
                    }
                    this.selectAreaData.push(obj)
                })
                form.area = form.areaCode
                specDTOList && specDTOList.forEach(item => {
                    this.productParamsInitObj[item.productSpecId] = item.attributeId
                })
            }
        },
        /**
         * 添加地区按钮
         * */
        handleAddArea() {
            const { selectAreaData, areaMessage, form } = this
            const { area } = form
            const areaLen = area.length
            if (!areaLen) {
                this.$message.error('请选择地区')
                return
            }
            const selectLen = selectAreaData.length
            const last = area[areaLen - 1]
            // const {mapArea, getChildIds, getName} = this.$refs.areaRef
            const { mapArea, getName } = this.$refs.areaRef
            if (selectLen && (last === COUNTRY_CODE || selectAreaData[0].value === COUNTRY_CODE)) {
                this.$message.error(areaMessage)
                return
            }
            // res 存储当前选择的所有子id和父id
            const res = [...area]
            getChildIds(mapArea, last, res)


            for (let i = 0; i < selectAreaData.length; i++) {
                const selectItem = selectAreaData[i]
                if (res.find(resItem => resItem === selectItem.value)) {
                    this.$message.error(areaMessage)
                    return
                }
            }
            let selectAreaObj = {
                name: '',
                value: ''
            }
            // 根据数组的三个值找到对应的名字
            selectAreaObj.name = getName(last).join('')
            // selectAreaObj.name = area.map(item => {
            //   return mapArea.get(item).label
            // }).join('')
            selectAreaObj.value = last
            this.selectAreaData.unshift(selectAreaObj)
        },
        handleDeleteArea(e, index) {
            e.preventDefault()
            this.selectAreaData.splice(index, 1)
        },
        handleFormValidate() {
            let res = null
            const { params } = this
            const { areaCode } = params
            const areaLen = areaCode.length
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (!areaLen) {
                        this.$message.error('请添加地区')
                        return res
                    }
                    res = params
                }
            })
            return res
        },
        /**
         * 分类数据拉取动态商品参数
         * */
        async getProductParamsByProductCategory(riskCategoryId) {
            try {
                this.form.productParamsData = await getProductParamsByProductCategoryService({
                    riskCategoryId
                })
                this.productParamsData = this.form.productParamsData
                const { productParamsInitObj } = this


                // 动态参数列表回显
                this.form.productParamsData.forEach(item => {
                    if (productParamsInitObj[item.productSpecId]) {
                        this.$set(item, 'attributeId', productParamsInitObj[item.productSpecId])
                    }
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>
.fill-commodity-base-info {
    &__area {
        border: 1px solid #e8e8e8;
        min-height: 200px;
        padding: 10px;
    }

    .ant-tag {
        padding: 5px 10px;
        font-size: 14px;
    }
}

</style>
