import { AUDIT_STATE_MAP, BUSINESS_TYPE_MAP } from '@constant/enum'
export const defaultColumns = [
    {
        title: '序号',
        dataIndex: 'sort',
        align: 'left',
        key: 'sort',
        fixed: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'sort'
        }
    },
    {
        title: '模型名称',
        dataIndex: 'modelExamplesName',
        align: 'left',
        key: 'modelExamplesName',
        width: 300
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        key: 'riskBusinessType',
        customRender: v => BUSINESS_TYPE_MAP[v],
        width: 200
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        key: 'riskCategoryName',
        width: 300
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        key: 'auditState',
        width: 200,
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '提交审核时间',
        dataIndex: 'submissionTime',
        align: 'left',
        key: 'submissionTime',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 200,
        fixed: 'right'
    }
]
export const riskClassColumns = [
    {
        title: '序号',
        dataIndex: 'sort',
        align: 'left',
        key: 'sort',
        fixed: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'sort'
        }
    },
    {
        title: '模型名称',
        dataIndex: 'modelExamplesName',
        align: 'left',
        key: 'modelExamplesName',
        width: 300
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        key: 'riskBusinessType',
        width: 200,
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        key: 'auditState',
        width: 200,
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '提交审核时间',
        dataIndex: 'submissionTime',
        align: 'left',
        key: 'submissionTime',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 200,
        fixed: 'right'
    }
]
