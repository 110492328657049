<template>
    <!-- 操作日志查看 -->
    <a-modal
        :visible="visible"
        title="查看明细"
        class="operation-log-detail-modal"
        :footer="null"
        :width="680"
        @cancel="handleOk"
    >
        <div class="operation-log-detail-modal__title">XXXXX数据名称：主数据名称-一级分类名称-二级分类名称。。。。。。</div>
        <a-table
            class="operation-log-detail-modal__table"
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :data-source="data"
        />
    </a-modal>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'

const columns = [
    {
        title: '字段名称',
        dataIndex: 'fieldName',
        align: 'left',
        key: 'fieldName',
        scopedSlots: {
            customRender: 'fieldName'
        },
        width: 150
    }, {
        title: '修改前',
        dataIndex: 'beforeUpdate',
        align: 'left',
        key: 'beforeUpdate',
        scopedSlots: {
            customRender: 'beforeUpdate'
        }
    // width: 150
    }, {
        title: '修改后',
        dataIndex: 'afterUpdate',
        align: 'left',
        key: 'afterUpdate',
        scopedSlots: {
            customRender: 'afterUpdate'
        }
    // width: 150
    }
]

export default {
    name: 'OperationLogDetailModal',
    components: {
        ATable
    },
    props: {
    // 日志id
        id: {
            type: String,
            default() {
                return ''
            }
        },
        // 显示隐藏
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            columns,
            data: []
        }
    },
    watch: {
        id: {
            handler(val) {
                if (val) {
                    this.getList()
                }
            },
            immediate: true
        }
    },
    methods: {
        handleOk() {
            this.$emit('close-model')
        },
        getList() {
            console.log('getList')
        }
    }
}
</script>

<style lang="scss">
.operation-log-detail-modal {
  &__title {
    margin-bottom: 15px;
    font-family: PingFangSC-Light, PingFang SC;
    color: #000;
  }
  &__table {
    margin-bottom: 50px;
  }
}
</style>
