<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType" />
        </a-form-model-item>
        <a-form-model-item label="参数名称" prop="name">
            <a-input v-model="form.name" :max-length="INPUT_MAX_LENGTH" placeholder="请输入参数名称" />
        </a-form-model-item>
        <a-form-model-item label="属性值" prop="attributeValues">
            <a-input
                v-model="attributes"
                type="textarea"
                :placeholder="attributePlaceholder"
                :rows="6" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'

import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'

import addParamService from '@service/commodityManage/paramsList/addParamService'
import editParamService from '@service/commodityManage/paramsList/editParamService'
import { INPUT_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'FormPanel',
    components: {
        RiskBusinessType,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create'
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            attributes: '',
            form: {
                name: '',
                riskBusinessType: '',
                attributeValues: '',
                id: ''
            },
            rules: {
                riskBusinessType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入业务类型'
                    }
                ],
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入分类'
                    }
                ],
                attributeValues: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入属性值'
                    }
                ]
            },
            attributePlaceholder: '请输入属性值，多个属性值换行输入，举例如下：\n项目版\n企业版'
        }
    },
    watch: {
        mode: {
            handler(value) {
                if (value === 'edit') {
                    const { id, name, riskBusinessType, attributeValues } = this.record
                    this.form.id = id
                    this.form.name = name
                    this.form.riskBusinessType = riskBusinessType
                    this.form.attributeValues = attributeValues
                    this.attributes = this.listToString(attributeValues)
                }
            },
            immediate: true
        },
        attributes(value) {
            this.form.attributeValues = value
        }
    },
    methods: {
        listToString(list) {
            return list.map(item => item.attributeValue).join('\n')
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                } else {
                    return false
                }
            })
        },
        async submitService() {
            let { attributes, form } = this
            let splitArr = attributes.split(/[\n]/)
            let hasId = form.id
            let serviceFn = hasId ? editParamService : addParamService
            let message = hasId ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
            form.attributeValues = [...new Set(splitArr)]
            try {
                this.$emit('loading', true)
                await serviceFn(form)
                this.$message.success(message)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
