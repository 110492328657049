<!-- 审批及日志-规则审核详情 -->
<template>
    <page-layout class="rule-approve-info">
        <container-panel class="rule-approve-info__container">
            <div slot="title" class="rule-approve-info__title">
                <a-avatar class="avatar" size="small" shape="circle" :src="avatar" />
                <span class="rule-approve-info__title-text">{{ info.modelExamplesName }}</span>
            </div>
            <a-button
                v-show="showAudit"
                slot="button"
                v-auth="APPROVE_MANAGE_RULE_APPROVE_DETAIL_AUDIT_BUTTON"
                type="primary"
                @click="handleApproveClick"
            >
                审核
            </a-button>
            <div class="rule-approve-info__info">
                <div class="rule-approve-info__info-panel">
                    <a-descriptions :column="1">
                        <a-descriptions-item label="创建人">{{ info.createName }}</a-descriptions-item>
                        <a-descriptions-item label="更新时间">{{ info.modifyDateTime }}</a-descriptions-item>
                    </a-descriptions>
                </div>
                <div class="rule-approve-info__info-status">
                    状态：{{ AUDIT_STATE_MAP[info.auditState] }}
                </div>
            </div>
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
        </container-panel>
        <component
            :is="defaultActiveKey"
            class="rule-approve-info__component"
            :info="info"
            :is-look="true" />
        <rule-approve-modal
            :visible="visible"
            :audit-service="auditService"
            @cancel="handleCancel"
            @success="handleSuccess" />
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton,
    Avatar as AAvatar
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import ContainerPanel from '@components/ContainerPanel'
import RuleApproveModal from './components/RuleApproveModal'

import { APPROVE_MANAGE_RULE_APPROVE_DETAIL_AUDIT_BUTTON } from '@constant/authEnum/approve'
import { AUDIT_STATE_MAP, DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'
import modelObj from './modelObj'

export default {
    name: 'RuleApproveInfo',
    components: {
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        AButton,
        AAvatar,
        DataCheckBasicInfoTabPanel: () => import('@weights/DataCheckInfo/BasicInfoTabPanel'),
        DataCheckLayoutCategoryTabPanel: () => import('@weights/DataCheckInfo/LayoutCategoryTabPanel'),
        DataCheckRiskFactorTabPanel: () => import('@weights/DataCheckInfo/RiskFactorTabPanel'),

        RiskAssessBasicInfoTabPanel: () => import('@weights/RiskAssessmentInfo/BasicInfoTabPanel'),
        RiskAssessLayoutCategoryTabPanel: () => import('@weights/RiskAssessmentInfo/LayoutCategoryTabPanel'),
        RiskAssessAssessmentContentTabPanel: () => import('@weights/RiskAssessmentInfo/AssessmentContentTabPanel'),

        GradeModelBasicInfoTabPanel: () => import('@weights/GradeModelInfo/BasicInfoTabPanel'),
        GradeModelGradeFactorTabPanel: () => import('@weights/GradeModelInfo/GradeFactorTabPanel'),

        RiskClassBasicInfoTabPanel: () => import('@views/RuleEngine/RiskClass/RiskClassInfo/BasicInfoTabPanel'),

        SmartAssessBasicInfoTabPanel: () => import('@views/RuleEngine/SmartAssessModel/SmartAssessInfo/BasicInfoTabPanel'),

        RuleSetBasicInfoTabPanel: () => import('@views/RuleEngine/RuleSet/RuleSetInfo/BaseInfoTabPanel'),
        RuleApproveModal
    },
    mixins: [enumOperate],
    data() {
        return {
            AUDIT_STATE_MAP,
            tabsData: [],
            defaultActiveKey: '',
            APPROVE_MANAGE_RULE_APPROVE_DETAIL_AUDIT_BUTTON,
            avatar:
                'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
            visible: false,
            modelObj,
            info: {},
            auditService: ''
        }
    },
    computed: {
        model() {
            return this.$route.query.model
        },
        id() {
            return this.$route.query.id
        },
        showAudit() {
            const { info } = this
            return info.auditState === DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
        }
    },
    created() {
        const { modelObj, model } = this
        const currentModel = modelObj[model]
        this.tabsData = currentModel.tabsData
        this.defaultActiveKey = currentModel.tabsData[0].key
        this.auditService = currentModel.auditService
        this.getInfo()
    },
    methods: {
        /**
         * tab改变
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
         * 审核
         */
        handleApproveClick() {
            this.visible = true
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.visible = false
        },
        handleSuccess() {
            this.visible = false
            this.getInfo()
        },
        async getInfo() {
            const { model, modelObj } = this
            const service = modelObj[model].service
            try {
                this.info = await service(this.id)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.rule-approve-info {
    &__container {
        .ant-card-body {
            padding: $ant-card-body-padding $ant-card-body-padding 0 $ant-card-body-padding;
        }

        .ant-tabs {
            border-top: 1px dashed $border-color;
        }
    }

    &__info {
        margin-top: -24px;
        display: flex;
    }

    &__title {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #0f0f0f;
    }

    &__title-text {
        vertical-align: middle;
        margin-left: 10px;
    }

    &__info-panel {
        flex: 1;
    }

    &__info-status {
        width: 250px;
        text-align: right;
        padding-right: 5px;
    }
}
</style>
