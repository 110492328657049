<!-- 人工审核 -->
<template>
    <descriptions-with-head title="人工审核（人工审核中风控初审为默认第一个审核节点，请至少选择一个审核节点）">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                label="风险评估模型"
                prop="manualAuditModelId">
                <a-select
                    v-model="form.manualAuditModelId"
                    style="width: 100%"
                    placeholder="请选择">
                    <a-select-option
                        v-for="item in dataList"
                        :key="item.id"
                        :value="item.id"
                        :label="item.modelExamplesName">
                        {{ item.modelExamplesName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="选择审核节点" prop="type">
                <a-checkbox-group v-model="form.type">
                    <a-checkbox value="riskControlReviewAudit" name="type">
                        风控复审
                    </a-checkbox>
                    <a-checkbox value="riskControlSeniorAudit" name="type">
                        风控高审
                    </a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import { FormModel as AFormModel, Select as ASelect, Checkbox as ACheckbox } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import { DEFAULT_PAGES_SIZE } from '@config'
import getRiskAssessmentModelListService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentModelListService'
import { DATA_STATE_TYPE_USED } from '@constant/enum'

const checkOptions = [
    {
        label: '风控复审',
        value: 'riskControlReviewAudit'
    }, {
        label: '风控高审',
        value: 'riskControlSeniorAudit'
    }
]
export default {
    name: 'PersonCheck',
    components: {
        DescriptionsWithHead,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ASelect,
        ASelectOption: ASelect.Option,
        ACheckboxGroup: ACheckbox.Group,
        ACheckbox
    },
    props: {
    // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            checkOptions,
            limit: DEFAULT_PAGES_SIZE,
            current: 1,
            total: 10,
            dataList: [],
            form: {
                manualAuditModelId: '', // 该模型的id
                riskControlReviewAudit: 0, // 风控复审
                riskControlSeniorAudit: 0, // 风控高审
                type: []// ACheckbox 选择的
            },
            rules: {
                manualAuditModelId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        params() {
            const { form } = this
            let params = JSON.parse(JSON.stringify(form))
            delete params.type
            return params
        }
    },
    watch: {
        riskCategoryId: {
            handler() {
                this.form.manualAuditModelId = ''
                this.getList()
            }
        },
        modelInfo: {
            handler(val) {
                const { productControlModel, riskControlReviewAudit, riskControlSeniorAudit } = val
                if (!productControlModel) return
                if (riskControlReviewAudit) {
                    this.form.type.push('riskControlReviewAudit')
                }
                if (riskControlSeniorAudit) {
                    this.form.type.push('riskControlSeniorAudit')
                }
                this.form.manualAuditModelId = productControlModel.modelId
            },
            immediate: true
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const typeArr = this.form.type
                    const { params } = this
                    typeArr.length && typeArr.forEach(item => {
                        params[item] = 1
                    })
                    res = params
                }
            })
            return res
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, riskCategoryId } = this
                const param = {
                    current,
                    size: limit,
                    riskCategoryId,
                    state: DATA_STATE_TYPE_USED
                }
                const { data } = await getRiskAssessmentModelListService(param)
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
