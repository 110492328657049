<template>
    <a-transfer
        class="select-risk-control-model"
        :data-source="mockData"
        :lazy="false"
        :list-style="{
            width: '250px',
            height: '300px',
            margin:'auto'
        }"
        :disabled="false"
        :titles="['未选择风控模型', '已选择风控模型']"
        :operations="['加入右侧', '加入左侧']"
        :target-keys="targetKeys"
        :render="item => `${item.title}`"
        @change="handleChange">
        <span slot="notFoundContent">
            没数据
        </span>
    </a-transfer>
</template>

<script>
import { Transfer as ATransfer } from 'ant-design-vue'
import getRiskControlModelListService from '@service/ruleEngine/riskControlModel/getRiskControlModelListService'
import {
    DATA_STATE_TYPE_USED,
    COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE,
    COMMODITY_CONFIG_MAP
} from '@constant/enum'
import { isRiskCategoryBid } from '@constant/Const'
import enumOperate from '@mixins/enumOperate'

export default {
    name: 'SelectRiskControlModel',
    components: {
        ATransfer
    },
    mixins: [enumOperate],
    props: {
        commodityInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String
        }
    },
    data() {
        return {
            mockData: [], // 左边数据
            targetKeys: [], // 右边数据
            dataList: [], // 模型数据
            modelBooleanObj: {}// 收集所有模型是否选择
        }
    },
    watch: {
        /* v2.0.1 投保险mockData 只显示黑名单*/
        riskCategoryId(val) {
            // 是否是农民工工资支付履约保证保险/企业版采购
            const isBid = isRiskCategoryBid(val)
            this.mockData.forEach(item => {
                const isBlack = +item.key === +COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE
                if (isBid) {
                    item.disabled = isBlack ? false : true
                } else {
                    item.disabled = false
                }
            })
        },
        targetKeys: {
            handler(val) {
                this.initBooleanObj()
                val.map(item => {
                    const key = COMMODITY_CONFIG_MAP[item].key
                    this.modelBooleanObj[key] = true
                })
            },
            immediate: true
        },
        commodityInfo: {
            handler(val) {
                const { riskControllerModelDTOS } = val
                if (riskControllerModelDTOS) {
                    riskControllerModelDTOS.map(item => {
                        if (item.isChoose) {
                            this.targetKeys.push(item.modelType.toString())
                        }
                    })
                }
            },
            immediate: true
        }

    },
    created() {
        this.getList()
        this.initBooleanObj()
    },
    methods: {
        /* 初始化*/
        initBooleanObj() {
            Object.keys(COMMODITY_CONFIG_MAP).forEach(item => {
                const key = COMMODITY_CONFIG_MAP[item].key
                this.modelBooleanObj[key] = false
            })
        },
        handleChange(targetKeys) {
            this.targetKeys = targetKeys
        },
        handleValidate() {
            const { targetKeys, dataList, modelBooleanObj } = this
            if (!targetKeys.length) {
                this.$message.error('请选择风控模型')
                return null
            }
            // 选择的  所有模型数据  所有模型是否选择obj
            return {
                targetKeys,
                dataList,
                modelBooleanObj
            }
        },
        async getList() {
            try {
                this.loading = true
                const param = {
                    current: 1,
                    size: 99,
                    state: DATA_STATE_TYPE_USED
                }
                const result = await getRiskControlModelListService(param)
                this.dataList = result.records

                this.mockData = this.dataList.map(item => ({
                    key: item.modelType.toString(),
                    title: item.modelName,
                    description: item.modelName,
                    disabled: false
                }))
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.select-risk-control-model {
    width: 610px;
    margin: auto
}
</style>
