<!-- 保司自有快速核保规则 -->
<template>
    <descriptions-with-head :title="BAOSI_ZIYOU_KUAISU_HEBAO">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <!-- 写死 -->
            <a-form-model-item
                label="限制名单">
                <a-select disabled default-value="无" />
            </a-form-model-item>
            <a-form-model-item
                :label="BAOSI_ZIYOU_KUAISU_HEBAO"
                prop="insuranceModelIds">
                <select-option-label-prop
                    v-model="form.insuranceModelIds"
                    ref="option"
                    :select-options-data="dataList"
                    option-label="modelExamplesName"
                    option-value="id"
                    placeholder="请选择规则"
                    :filter-option="false" />
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import { FormModel as AFormModel, Select as ASelect } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import getAllRuleListService from '@service/ruleEngine/ruleSet/getAllRuleListService'
import { DEFAULT_PAGES_SIZE } from '@config'
import { DATA_STATE_TYPE_USED, RULE_SET_TYPE_A3_VALUE, COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_LABEL as BAOSI_ZIYOU_KUAISU_HEBAO } from '@constant/enum'

export default {
    name: 'InsureRuleModel',
    components: {
        ASelect,
        DescriptionsWithHead,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        SelectOptionLabelProp: () => import('@weights/SelectOptionLabelProp')
    },
    props: {
        // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            BAOSI_ZIYOU_KUAISU_HEBAO,
            limit: DEFAULT_PAGES_SIZE,
            current: 1,
            dataList: [],
            form: {
                insuranceModelIds: []
            },
            rules: {
                insuranceModelIds: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        riskCategoryId: {
            handler() {
                this.form.insuranceModelIds = []
                this.getList()
            }
        },
        modelInfo: {
            handler(val) {
                const { ruleSetList = [] } = val
                if (!ruleSetList.length) return
                this.form.insuranceModelIds = ruleSetList.map(item => item.id)
            },
            immediate: true
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { insuranceModelIds } = this.form
                    res = {
                        insuranceModelId: insuranceModelIds.join('|')
                    }
                }
            })
            return res
        },
        async getList() {
            try {
                this.loading = true
                const { riskCategoryId } = this
                const param = {
                    state: DATA_STATE_TYPE_USED,
                    ruleSetType: RULE_SET_TYPE_A3_VALUE,
                    riskCategoryId
                }
                this.dataList = await getAllRuleListService(param)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
