<!-- 特征工程评分模型 -->
<template>
    <descriptions-with-head :title="COMMODITY_CONFIG_PROJECT_SCORE_LABEL">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                prop="ceScoringModelId"
                :label="COMMODITY_CONFIG_PROJECT_SCORE_LABEL"
            >
                <!-- 特征工程 -->
                <grade-model-select
                    v-model="form.ceScoringModelId"
                    :scoring-model-type="PROJECT_SCORE_CARD_VALUE" />
            </a-form-model-item>

            <a-form-model-item
                label="保司选择"
                prop="ceScoringModelCompanys">
                <sys-list
                    v-model="form.ceScoringModelCompanys"
                    ref="sysList"
                    show-select-all
                    mode="multiple" />
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import { FormModel as AFormModel } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import getAllRuleListService from '@service/ruleEngine/ruleSet/getAllRuleListService'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    DATA_STATE_TYPE_USED,
    RULE_SET_TYPE_A3_VALUE,
    COMMODITY_CONFIG_PROJECT_SCORE_LABEL,
    PROJECT_SCORE_CARD_VALUE
} from '@constant/enum'

export default {
    name: 'InsureRuleModel',
    components: {
        DescriptionsWithHead,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        SysList: () => import('@weights/select/SysList'),
        GradeModelSelect: () => import('@weights/select/GradeModelSelect')
    },
    props: {
        // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            COMMODITY_CONFIG_PROJECT_SCORE_LABEL,
            PROJECT_SCORE_CARD_VALUE,
            limit: DEFAULT_PAGES_SIZE,
            current: 1,
            dataList: [],
            form: {
                ceScoringModelId: undefined,
                ceScoringModelCompanys: []
            },
            rules: {
                ceScoringModelId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        riskCategoryId: {
            handler() {
                this.from = {
                    ceScoringModelId: undefined,
                    ceScoringModelCompanys: undefined
                }
                this.getList()
            }
        },
        modelInfo: {
            handler(val) {
                const { modelId, gsysDictList = [] } = val.productControlModel || {}
                if (!modelId) return
                this.form = {
                    ceScoringModelId: modelId,
                    ceScoringModelCompanys: gsysDictList.map(item => item.dataCode)
                }
            },
            immediate: true
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { ceScoringModelId, ceScoringModelCompanys } = this.form
                    res = {
                        ceScoringModelId,
                        ceScoringModelCompanys: ceScoringModelCompanys.join('|')
                    }
                }
            })
            return res
        },
        async getList() {
            try {
                this.loading = true
                const { riskCategoryId } = this
                const param = {
                    state: DATA_STATE_TYPE_USED,
                    ruleSetType: RULE_SET_TYPE_A3_VALUE,
                    riskCategoryId
                }
                this.dataList = await getAllRuleListService(param)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
