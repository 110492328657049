<!-- 商品审核 -->
<template>
    <list-page-layout>
        <div slot="header">
            <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        </div>
        <container-panel :no-card-body-padding-top="true">
            <template slot="title">商品审核列表</template>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="APPROVE_MANAGE_COMMODITY_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON"
                            type="link"
                            @click="handleDeatil(action)"
                        >
                            查看
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>
<script >
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

import { APPROVE_MANAGE_COMMODITY_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON } from '@constant/authEnum/approve'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT, AUDIT_STATE_MAP,
    BUSINESS_TYPE_MAP
} from '@constant/enum'
import tableShowTotal from '@mixins/tableShowTotal'
import getCommodityListService from '@service/auditAndLog/getCommodityListService'
import enumOperate from '@mixins/enumOperate'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '商品名称',
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        width: 300
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        key: 'riskCategoryName',
        width: 300
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        key: 'riskBusinessType',
        width: 200,
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        key: 'auditState',
        width: 200,
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '提交审核时间',
        dataIndex: 'submissionTime',
        align: 'left',
        key: 'submissionTime',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 200,
        fixed: 'right'
    }
]

export default {
    name: 'CommodityApproveList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        AButton,
        ATable
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            APPROVE_MANAGE_COMMODITY_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON, // 查看
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params,
                    auditState: DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
                }
                const result = await getCommodityListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleExport() {},
        handleDeatil(action) {
            const { id } = action
            this.$router.push({
                name: 'CommodityApproveInfo',
                query: {
                    id
                }
            })
        }
    }
}
</script>
<style lang="scss">
</style>
