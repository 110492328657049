<!-- AI -->
<template>
    <descriptions-with-head
        v-if="false"
        title="AI智能模型">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <!--      <a-form-model-item -->
            <!--          label="业务对象" -->
            <!--          prop="businessObjectArr"> -->
            <!--        <business-object-select -->
            <!--            v-model="form.businessObjectArr" -->
            <!--            @change="handleChange"/> -->
            <!--      </a-form-model-item> -->
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import { FormModel as AFormModel } from 'ant-design-vue'

export default {
    name: 'AiModel',
    components: {
        DescriptionsWithHead,
        AFormModel
    },
    props: {
    // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            form: {
                businessObjectArr: [],
                blackObjectVOS: []
            },
            rules: {}
        }
    },
    watch: {
        modelInfo: {
            handler(val) {
                if (!val.blackObjectVOS) return
                const { blackObjectVOS } = val
                this.form.blackObjectVOS = blackObjectVOS
                blackObjectVOS.map(item => {
                    this.form.businessObjectArr.push(item.objectCode)
                })
            },
            immediate: true
        }
    },
    methods: {
        handleChange(res) {
            this.form.blackObjectVOS = res
        },
        handleFormValidate() {
            let res = 1
            // this.$refs.ruleForm.validate(valid => {
            //   if (valid) {
            //     let resultObj = JSON.parse(JSON.stringify(this.form))
            //     delete resultObj.businessObjectArr
            //     res = resultObj
            //   }
            // })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
