<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col :span="8" label="操作内容" prop="content">
            <template slot="formElements">
                <a-input v-model="form.content" placeholder="操作内容" />
            </template>
        </form-col>
        <form-col :span="8" label="操作者" prop="user">
            <template slot="formElements">
                <a-input v-model="form.user" placeholder="操作者" />
            </template>
        </form-col>
        <form-col :span="8" label="操作分类" prop="operationType">
            <template slot="formElements">
                <a-select v-model="form.operationType" placeholder="请选择操作分类">
                    <a-select-option :value="1">分类21</a-select-option>
                    <a-select-option :value="2">分类2</a-select-option>
                    <a-select-option :value="3">分类3</a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col :span="8" label="操作时间" prop="operationTime">
            <template slot="formElements">
                <a-date-picker
                    v-model="form.formTime"
                    slot="formElements"
                    format="YYYY-MM-DD HH:mm:ss"
                    :disabled-date="disabledDate"
                    @change="handleTimeChange" />
                <!--        <a-range-picker v-model="form.operationTime" :mode="timePickerMode" /> -->
            </template>
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput,
    Select as ASelect,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'
import moment from 'moment'

export default {
    name: 'OperationLogListSearchpanel',
    components: {
        AInput,
        ASelect,
        ASelectOption: ASelect.Option,
        ADatePicker,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                content: '',
                user: '',
                operationType: '',
                operationTime: [],
                startTime: ''
            },
            timePickerMode: ['date', 'date']
        }
    },
    methods: {
        handleChange(value) {
            this.form.riskCategoryId = value
        },
        handleSearch() {
            const { form } = this
            let { ...params } = form
            this.$emit('search', filterParams(params))
        },
        handleRefresh() {
            const { form } = this
            let { ...params } = form
            this.$emit('refresh', filterParams(params))
        },
        disabledDate(current) {
            return current && current > moment().endOf('day')
        },
        handleTimeChange(moment, stringTime) {
            this.form.startTime = stringTime
        }
    }
}
</script>

<style lang="scss">
</style>
