<!-- 审批及日志-规则审核 -->
<template>
    <list-page-layout>
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel :no-card-body-padding-top="true">
            <template slot="title">日志列表</template>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="startTime"
                    @change="handleTableChange"
                >
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-if="showLookInfo(action)"
                            v-auth="APPROVE_MANAGE_LOG_APPROVE_BUTTON"
                            type="link"
                            @click="handleDeatil(action)"
                        >
                            查看明细
                        </a-button>
                        <template v-else-if="showAudit(action)">
                            审核通过/审核不通过
                        </template>
                        <template v-else>/</template>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <operation-log-detail-modal :id="currentId" :visible="visible" @close-model="handleCancel" />
    </list-page-layout>
</template>
<script >
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import OperationLogDetailModal from './components/OperationLogDetailModal'

import { APPROVE_MANAGE_LOG_APPROVE_BUTTON } from '@constant/authEnum/approve'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'

import getLogListService from '@service/auditAndLog/getLogListService'
const columns = [
    {
        title: '操作者',
        dataIndex: 'user',
        align: 'left',
        key: 'user',
        width: 200
    },
    {
        title: '操作时间',
        dataIndex: 'startTime',
        align: 'left',
        key: 'startTime',
        width: 200
    },
    {
        title: '操作分类',
        dataIndex: 'operationType',
        align: 'left',
        key: 'operationType',
        width: 200
    },
    {
        title: '操作内容',
        dataIndex: 'content',
        align: 'left',
        key: 'content',
        width: 400
    },
    {
        title: '操作事项',
        dataIndex: 'event',
        align: 'left',
        key: 'event',
        width: 200
    },
    {
        title: '备注',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150,
        fixed: 'right'
    }
]

export default {
    name: 'OperationLogList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        AButton,
        ATable,
        OperationLogDetailModal
    },
    mixins: [tableShowTotal],
    data() {
        return {
            APPROVE_MANAGE_LOG_APPROVE_BUTTON, // 查看
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            },
            tabData: [
                {
                    key: '1',
                    label: '数据核验模型审核'
                }, {
                    key: '2',
                    label: '评分模型审核'
                }, {
                    key: '3',
                    label: '风险评估模型审核'
                }
            ],
            defaultActiveKey: '1',
            currentId: ''
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
    // 编辑可查看详情
        showLookInfo(action) {
            const { event } = action
            return event === '编辑'
        },
        // 审核显示审核状态
        showAudit(action) {
            const { event } = action
            return event === '审核'
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getLogListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleExport() {},
        handleDeatil(action) {
            const { id } = action
            this.visible = true
            this.currentId = id
        },
        handleRadioGroupChange(e) {
            const { key } = e.target | {}
            this.defaultActiveKey = key
            this.current = 1
            this.getList()
        },
        handleCancel() {
            this.visible = false
            this.currentId = ''
        }
    }
}
</script>
<style lang="scss">
</style>
