<template>
    <list-page-layout>
        <search-panel slot="header" @refresh="handleSearch" @search="handleSearch" />
        <container-panel>
            <template slot="title">
                参数列表
            </template>
            <a-button slot="button"
                      v-auth="COMMODITY_MANAGE_ADD_PARAMS_BUTTON"
                      type="primary"
                      @click="handleAdd">
                添加参数
            </a-button>
            <!--      <template slot-scope=""> -->
            <div>
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <template slot="state" slot-scope="text,action">
                        <a-switch :checked="Boolean(action.state)" @change="value => handleSwitch(value, action)" />
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="COMMODITY_MANAGE_EDIT_PARAMS_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                        <a-button
                            v-auth="COMMODITY_MANAGE_DELETE_PARAMS_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleDelete(action)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </div>
        </container-panel>
        <add-or-edit-param-modal
            :mode="mode"
            :visible="visible"
            :record="record"
            @cancel="handleCancel"
            @success="handleSuccess" />
    </list-page-layout>
</template>


<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import AddOrEditParamModal from './AddOrEditParamModal'
import SearchPanel from './SearchPanel'
import {
    COMMODITY_MANAGE_ADD_PARAMS_BUTTON,
    COMMODITY_MANAGE_EDIT_PARAMS_BUTTON,
    COMMODITY_MANAGE_DELETE_PARAMS_BUTTON
} from '@constant/authEnum/commodity'
import { DEFAULT_PAGES_SIZE } from '@config'
import { BUSINESS_TYPE_MAP } from '@constant/enum'
import { ERROR_COMMIT_OPEN, ERROR_COMMIT_CLOSE, ERROR_COMMIT_DELETE } from '@constant/error'
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getParamsListService from '@service/commodityManage/paramsList/getParamsListService'
import deleteParamService from '@service/commodityManage/paramsList/deleteParamService'
import openOrCloseParamSwitchService from '@service/commodityManage/paramsList/openOrCloseParamSwitchService'
import { scrollConfig } from '@constant/Const'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        customRender: (v, r, idx) => ++idx,
        key: 'index'
    },
    {
        title: '参数名称',
        dataIndex: 'name',
        align: 'left',
        width: 300,
        key: 'name'
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        width: 200,
        key: 'riskBusinessType',
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        width: 200,
        align: 'left',
        scopedSlots: {
            customRender: 'state'
        },
        key: 'state'
    },
    {
        title: '操作',
        key: 'action',
        width: 300,
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'ParamsList',
    components: {
        ListPageLayout,
        ContainerPanel,
        AddOrEditParamModal,
        AButton,
        ASwitch,
        ATable,
        SearchPanel
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            COMMODITY_MANAGE_ADD_PARAMS_BUTTON,
            COMMODITY_MANAGE_EDIT_PARAMS_BUTTON,
            COMMODITY_MANAGE_DELETE_PARAMS_BUTTON,
            columns,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            mode: '',
            visible: false,
            record: {},
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async handleSwitch(value, record) {
            try {
                let param = {
                    enable: +value,
                    id: record.id
                }
                await openOrCloseParamSwitchService(param)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleAdd() {
            this.mode = 'create'
            this.visible = true
        },
        handleEdit(action) {
            this.mode = 'edit'
            this.visible = true
            this.record = action
        },
        // 添加成功
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleDelete(action) {
            this.$confirm({
                title: '确认删除当前参数吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteParam(action)
                },
                onCancel() {
                }
            })
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async deleteParam(action) {
            try {
                let { id } = action
                await deleteParamService({
                    id
                })
                this.$message.success(ERROR_COMMIT_DELETE)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getParamsListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
