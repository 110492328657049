<template>
    <search-panel
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh">
        <form-col
            v-bind="colProps"
            label="商品名称"
            prop="name">
            <template slot="formElements">
                <a-input v-model="form.name" placeholder="请输入" />
            </template>
        </form-col>
        <form-col
            v-bind="colProps"
            label="产品分类"
            prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId"
                                       slot="formElements" />
        </form-col>
        <form-col
            v-bind="colProps"
            label="订单来源"
            prop="orderSource">
            <order-source-select v-model="form.orderSource" slot="formElements" />
        </form-col>
        <form-col  v-bind="colProps" label="是否启用" prop="state">
            <state-select
                v-model="form.state"
                slot="formElements" />
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import { filterParams } from '@utils/search'

export default {
    name: 'PolicyListSearchPanel',
    components: {
        AInput,
        FormCol,
        SearchPanel,
        ProductCategoryCascader,
        OrderSourceSelect: () => import('@components/select/OrderSourceSelect'),
        StateSelect: () => import('@components/select/StateSelect')
    },
    data() {
        return {
            colProps: {
                span: 7
            },
            form: {
                name: '',
                riskCategoryId: ''
            }
        }
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style>

</style>
