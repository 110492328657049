<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <div slot="title">筛选查询</div>
        <form-col :span="6" label="规则名称" prop="query">
            <template slot="formElements">
                <a-input v-model="form.query" placeholder="规则名称" />
            </template>
        </form-col>
        <form-col :span="6" label="产品分类" prop="riskCategoryId">
            <template slot="formElements">
                <product-category-cascader v-model="form.riskCategoryId" />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

export default {
    name: 'RuleApproveListSearchpanel',
    components: {
        ProductCategoryCascader,
        AInput,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                query: '',
                riskCategoryId: ''
            }
        }
    },
    methods: {
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style lang="scss">
</style>
