<!-- 风险提示 -->
<template>
    <descriptions-with-head title="风险提示">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                label="业务对象"
                prop="riskWarningArr">
                <business-object-select
                    v-model="form.riskWarningArr"
                    @change="handleChange" />
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import BusinessObjectSelect from '@weights/GbCloud/BusinessObjectSelect'
import { FormModel as AFormModel } from 'ant-design-vue'

export default {
    name: 'RiskRemind',
    components: {
        DescriptionsWithHead,
        BusinessObjectSelect,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
    // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            form: {
                riskWarningArr: [], // 前端展示的数据
                riskWoringObjectVOS: [] // 后端需要的参数
            },
            rules: {
                riskWarningArr: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        modelInfo: {
            handler(val) {
                if (!val.riskWoringObjectVOS) return
                const { riskWoringObjectVOS } = val
                this.form.riskWoringObjectVOS = riskWoringObjectVOS
                riskWoringObjectVOS.map(item => {
                    this.form.riskWarningArr.push(item.objectCode)
                })
            },
            immediate: true
        }
    },
    methods: {
        handleChange(res) {
            this.form.riskWoringObjectVOS = res
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let resultObj = JSON.parse(JSON.stringify(this.form))
                    delete resultObj.riskWarningArr
                    res = resultObj
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
