<!-- 规则审核弹框 -->
<template>
    <custom-modal
        title="规则审核"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel"
    >
        <template slot="customFooter">
            <a-button key="submit" type="primary" :loading="passLoading" @click="handleOk(true,'passLoading')">通过</a-button>
            <a-button key="reset"  :loading="rejectLoading" @click="handleOk(false,'rejectLoading')">驳回</a-button>
        </template>
        <form-panel
            ref="formPanel"
            :info="info"
            :audit-service="auditService"
            @passLoading="handlePassLoading"
            @rejectLoading="handleRejectLoading"
            @success="handleSuccess"
        />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'

export default {
    name: 'RuleApproveModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        auditService: {
            type: Function,
            default() {
                return () => {}
            }
        }
    },
    data() {
        return {
            passLoading: false,
            rejectLoading: false
        }
    },
    computed: {},
    methods: {
        handlePassLoading(value) {
            this.passLoading = value
        },
        handleRejectLoading(value) {
            this.rejectLoading = value
        },
        handleCancel() {
            this.$emit('cancel')
        },
        // agree 是否通过
        // loadingName 加载都loading
        handleOk(...arg) {
            this.$refs.formPanel.handleSubmit(...arg)
        },
        handleSuccess() {
            this.loading = false
            this.$emit('success')
        }
    }
}
</script>

<style lang='scss'>
</style>
