<!-- 智能评估 -->
<template>
    <descriptions-with-head title="智能评估" class="smart-assess-desc-head">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                label="智能评估模型"
                prop="intelligentEvaluationModelId">
                <a-select
                    v-model="form.intelligentEvaluationModelId"
                    placeholder="请选择智能评估模型"
                    option-label-prop="label">
                    <a-select-option
                        v-for="item in dataList"
                        :key="item.id"
                        :label="item.modelExamplesName"
                        :value="item.id">
                        {{ item.modelExamplesName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import { FormModel as AFormModel, Select as ASelect } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import { PAGE_SIZE_MAX_LENGTH } from '@constant/Const'
import { DATA_STATE_TYPE_USED, LESS_THAN_TYPE_ENUM_LIST } from '@constant/enum'
import getSmartAssessModelListService from '@service/ruleEngine/smartAssessModel/getSmartAssessModelListService'

export default {
    name: 'SmartAssess',
    components: {
        DescriptionsWithHead,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        riskBusinessType: {
            type: [Number, String]
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            LESS_THAN_TYPE_ENUM_LIST,
            current: 1,
            dataList: [],
            form: {
                intelligentEvaluationModelId: ''// 模型id
            },
            rules: {
                intelligentEvaluationModelId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        modelInfo: {
            handler(val) {
                const { productControlModel } = val
                if (!productControlModel) return
                this.form.intelligentEvaluationModelId = productControlModel.modelId
            },
            immediate: true
        },
        riskCategoryId: {
            handler() {
                this.form.intelligentEvaluationModelId = ''
                this.getList()
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.form
                }
            })
            return res
        },
        async getList() {
            const { riskBusinessType } = this
            try {
                this.loading = true
                const { current, riskCategoryId } = this
                const param = {
                    current,
                    riskBusinessType,
                    riskCategoryId,
                    size: PAGE_SIZE_MAX_LENGTH,
                    state: DATA_STATE_TYPE_USED
                }
                const { data } = await getSmartAssessModelListService(param)
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.smart-assess-desc-head {
    .ant-form-item-children {
        display: flex;
        align-items: center;
    }

    .select-option-label-prop {
        margin-right: 4px;
    }
}
</style>
