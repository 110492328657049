<template>
    <list-page-layout>
        <search-panel
            slot="header"
            @refresh="handleSearch"
            @search="handleSearch" />
        <container-panel>
            <template slot="title">
                商品列表
            </template>
            <a-button slot="button"
                      v-auth="PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE"
                      type="primary"
                      @click="handleAdd">
                添加商品
            </a-button>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         row-key="id"
                         :scroll="scrollConfig"
                         @change="handleTableChange">
                    <template slot="state" slot-scope="text,action">
                        <a-switch
                            :disabled="action.auditState!==DATA_CHECK_MODEL_AUDIT_STATE_PASS"
                            :checked="Boolean(action.state)"
                            @change="value => handleSwitch(value, action)" />
                    </template>
                    <template slot="riskOrderSum" slot-scope="text,action">
                        <a-button
                            v-auth="PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE"
                            type="link"
                            @click="handleOrder(action)">
                            {{ text }}
                        </a-button>
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE"
                            type="link"
                            @click="handleLook(action)">
                            查看
                        </a-button>
                        <a-button
                            v-auth="COMMODITY_MANAGE_COPY_COMMODITY_BUTTON"
                            type="link"
                            @click="handleCopy(action)">
                            复制
                        </a-button>
                        <a-button
                            v-if="showDelete(action)"
                            v-auth="COMMODITY_MANAGE_LOGIC_DELETE_COMMODITY_BUTTON"
                            :disabled="!!action.state"
                            type="link"
                            @click="handleDelete(action.id)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <confirm-delete
            v-bind="deleteProp"
            @close="deleteProp.visible=false"
            @ok="sendDeleteService" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'
import {
    COMMODITY_MANAGE_COPY_COMMODITY_BUTTON,
    COMMODITY_MANAGE_LOGIC_DELETE_COMMODITY_BUTTON
} from '@constant/authEnum/commodity'
import {
    PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE
} from '@constant/menu_code'
import { DATA_CHECK_MODEL_AUDIT_STATE_PASS, AUDIT_STATE_MAP, RULE_ENGINE_DELETE_BUTTON, ORDER_SOURCE_MAP } from '@constant/enum'
import { ERROR_COMMIT_OPEN, ERROR_COMMIT_CLOSE, ERROR_COMMIT_DELETE } from '@constant/error'

import getCommodityListService from '@service/commodityManage/commodityConfig/getCommodityListService'
import openOrCloseCommoditySwitchService from '@service/commodityManage/commodityConfig/openOrCloseCommoditySwitchService'
import logicDeleteModelService from '@service/commodityManage/commodityConfig/logicDeleteCommodityService'
import initTable from '@mixins/initTable'
import deleteModel from '@mixins/deleteModel'
import { open } from '@utils'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, idx) => ++idx,
        fixed: 'left'
    },
    {
        title: '商品名称',
        dataIndex: 'name',
        align: 'left',
        width: 200,
        key: 'name'
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        width: 340,
        key: 'riskCategoryName'
    },
    {
        title: '订单来源',
        dataIndex: 'orderSource',
        align: 'left',
        width: 160,
        key: 'orderSource',
        customRender: v => ORDER_SOURCE_MAP[v]
    },
    {
        title: '地区范围',
        dataIndex: 'areaName',
        align: 'left',
        width: 200,
        key: 'areaName'
    },
    {
        title: '订单数量',
        dataIndex: 'riskOrderSum',
        align: 'left',
        width: 120,
        key: 'riskOrderSum',
        scopedSlots: {
            customRender: 'riskOrderSum'
        }
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        width: 120,
        key: 'auditState',
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        width: 120,
        scopedSlots: {
            customRender: 'state'
        },
        key: 'state'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        },
        fixed: 'right'
    }
]
export default {
    name: 'CommodityList',
    components: {
        ListPageLayout,
        ContainerPanel,
        AButton,
        ASwitch,
        ATable,
        SearchPanel
    },
    mixins: [initTable, deleteModel],
    data() {
        return {
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE,
            PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE,
            COMMODITY_MANAGE_COPY_COMMODITY_BUTTON,
            COMMODITY_MANAGE_LOGIC_DELETE_COMMODITY_BUTTON,
            columns,
            params: {},
            record: {}
        }
    },
    methods: {
        handleOrder({ id }) {
            open.call(this, 'OrderList', {
                id
            })
        },
        handleLook({ id }) {
            open.call(this, 'CommodityInfo', {
                id
            })
        },
        handleCopy({ id }) {
            this.$router.push({
                name: 'AddCommodity',
                query: {
                    id,
                    copy: 1
                }
            })
        },
        async handleSwitch(value, record) {
            try {
                let param = {
                    enable: +value,
                    id: record.id
                }
                await openOrCloseCommoditySwitchService(param)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleAdd() {
            this.$router.push({
                name: 'AddCommodity'
            })
        },
        async sendDeleteService(params) {
            try {
                await logicDeleteModelService({
                    ...params,
                    id: this.deleteProp.id
                })
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
                this.deleteProp.visible = false
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    logicDelete: 0,
                    ...params
                }
                const { data, buttonPermission } = await getCommodityListService(param)
                this.hasDeletePermission = buttonPermission[RULE_ENGINE_DELETE_BUTTON]
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
