<template>
    <div class="config-ro-model">
        <component
            :is="isCom(item)"
            v-for="item in targetKeys"
            :key="item"
            :ref="`model${item}`"
            :model-info="giveModelInfo(item)"
            :risk-category-id="riskCategoryId" />
    </div>
</template>

<script>
import { COMMODITY_CONFIG_MAP } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'

export default {
    name: 'ConfigRiskControlModel',
    components: {
        BlackList: () => import('./BlackList'),
        DataCheckModel: () => import('./DataCheckModel'),
        RiskRemind: () => import('./RiskRemind'),
        GradeModel: () => import('./GradeModel'),
        SmartAssess: () => import('./SmartAssess'),
        PersonCheck: () => import('./PersonCheck'),
        AiModel: () => import('./AiModel'),
        EnterpriseModel: () => import('./EnterpriseModel'),
        AutoUnderModel: () => import('./AutoUnderModel'), // 保司通用自核规则A1
        InsureRuleModel: () => import('./InsureRuleModel'), // 保司自有快速核保规则
        InsureA2RuleModel: () => import('./InsureA2RuleModel'), // 保司自用自核规则
        ProjectScoreModel: () => import('./ProjectScoreModel') // 特征工程评分
    },
    mixins: [enumOperate],
    props: {
        // 选择的风控模型modelType数组
        targetKeys: {
            type: Array,
            default() {
                return []
            }
        },
        // 风控模型所有数据
        riskControlData: {
            type: Array,
            default() {
                return []
            }
        },
        // 商品详情数据
        commodityInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    methods: {
        // 给组件动态传值
        giveModelInfo(modelType) {
            const { commodityInfo } = this
            let res = {}
            if (commodityInfo.riskControllerModelDTOS) {
                res = commodityInfo.riskControllerModelDTOS.find(item => +item.modelType === +modelType)
            }
            return res
        },
        handleValidate() {
            const { targetKeys } = this
            let resObj = {}
            let checkPass = targetKeys.every(item => {
                const res = this.$refs[`model${item}`][0].handleFormValidate()
                Object.assign(resObj, res)// 收集所有组件的form数据
                return res
            })
            // 如果所有组件都校验通过
            if (checkPass) {
                return resObj
            }
        },
        isCom(item) {
            return COMMODITY_CONFIG_MAP[item].com
        }
    }
}
</script>

<style lang='scss'>
.config-ro-model {
    .ant-form-item-label {
        min-width: 140px;
    }
}
</style>
