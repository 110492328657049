<template>
    <page-layout class="add-commodity" :show-loading="true" :loading="loading">
        <steps-container>
            <a-steps :current="step.current" label-placement="vertical">
                <a-step title="填写基础信息" />
                <a-step title="选择风控模型" />
                <a-step title="配置风控模型" />
            </a-steps>
        </steps-container>

        <container-panel class="add-commodity__container">
            <template slot="title">
                {{ step.title }}
            </template>
            <div slot="content" class="add-commodity__container__content">
                <!-- 第一步 基本信息 -->
                <fill-in-basic-info
                    v-show="step.current === 0"
                    ref="basicRef"
                    :commodity-info="commodityInfo" />
                <!-- 第二步 选择风控模型 -->
                <select-risk-control-model
                    v-show="step.current === 1"
                    ref="selectRef"
                    :risk-category-id="selectRiskCategoryId"
                    :commodity-info="commodityInfo" />
                <!-- 第三步 配置风控模型 -->
                <config-risk-control-model
                    v-show="step.current === 2"
                    ref="configRef"
                    :risk-category-id="selectRiskCategoryId"
                    :commodity-info="commodityInfo"
                    :risk-control-data="riskControlData"
                    :target-keys="targetKeys" />
                <a-form-model-item :wrapper-col="{ span: 12, offset: 6 }" class="add-commodity__btn-group">
                    <custom-button v-show="showPrevButton()" text="上一步" type="default" @click="handlePrev" />
                    <a-button type="primary" :loading="submitLoading" @click="handleNext">
                        {{ step.btnText }}
                    </a-button>
                </a-form-model-item>
            </div>
        </container-panel>
    </page-layout>
</template>

<script>
import FillInBasicInfo from './FillInBasicInfo'
import SelectRiskControlModel from './SelectRiskControlModel'
import ConfigRiskControlModel from './ConfigRiskControlModel'
import {
    Steps as ASteps,
    FormModel as AFormModel,
    Button as AButton
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import StepsContainer from '@components/StepsContainer'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'

import addCommodityService from '@service/commodityManage/commodityConfig/addCommodityService'
import editCommodityService from '@service/commodityManage/commodityConfig/editCommodityService'
import getCommodityInfoService from '@service/commodityManage/commodityConfig/getCommodityInfoService'
import getCopyCommodityInfoService from '@service/commodityManage/commodityConfig/getCopyCommodityInfoService'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'

export default {
    name: 'ProductEditor',
    components: {
        FillInBasicInfo,
        SelectRiskControlModel,
        ConfigRiskControlModel,
        PageLayout,
        ASteps,
        AStep: ASteps.Step,
        StepsContainer,
        ContainerPanel,
        AFormModelItem: AFormModel.Item,
        AButton,
        CustomButton
    },
    props: {},
    data() {
        return {
            // step参数
            step: {
                current: 0,
                title: '',
                btnText: ''
            },
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 22
            },
            edit: false,
            loading: false, // 页面loading
            submitLoading: false, // 提交按钮loading
            riskControlData: [], // 模型数据
            targetKeys: [], // 选择的模型
            addCommodityParams: {}, // 添加商品的所有参数
            commodityInfo: {}, // 商品详情数据
            selectRiskCategoryId: ''// 已经选择的分类id
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id || ''
        },
        serviceFn() {
            const { isEdit } = this
            return isEdit ? editCommodityService : addCommodityService
        },
        commitMsg() {
            const { isEdit } = this
            return isEdit ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        },
        // 修改
        isEdit() {
            const { queryId, isCopy } = this
            return queryId && !isCopy
        },
        // 复制
        isCopy() {
            return !!this.$route.query.copy
        },
        // 详情页接口
        getInfoServe() {
            const { isCopy } = this
            return isCopy ? getCopyCommodityInfoService : getCommodityInfoService
        }
    },
    watch: {
        'step.current':
            {
                handler(current) {
                    let title = ''
                    let btnText = ''
                    switch (current) {
                        case 0 : {
                            title = '填写基础信息'
                            btnText = '下一步，选择风控模型'
                            break
                        }
                        case 1: {
                            title = '选择风控模型'
                            btnText = '下一步，配置风控模型'
                            break
                        }
                        case 2 : {
                            title = '配置风控模型'
                            btnText = '提交'
                            break
                        }
                    }
                    this.step.title = title
                    this.step.btnText = btnText
                },
                immediate: true
            },
        queryId: {
            handler(val) {
                if (!val) return
                this.addCommodityParams.id = val
                this.getCommodityInfo()
            },
            immediate: true
        }

    },
    methods: {
        showPrevButton() {
            const { current } = this.step
            return current === 1 || current === 2
        },
        /**
         * 上一步
         */
        handlePrev() {
            const { current } = this.step
            this.step.current = current - 1
        },
        /**
         * 下一步
         */
        handleNext() {
            let { current } = this.step
            const { addCommodityParams } = this
            switch (current) {
                // 第一步
                case 0 : {
                    const res = this.$refs.basicRef.handleFormValidate()
                    if (res) {
                        this.step.current = 1
                        this.addCommodityParams = {
                            ...addCommodityParams,
                            ...res
                        }
                        this.selectRiskCategoryId = res.riskCategoryId
                    }
                }
                    break
                // 第二步 产品信息
                case 1 : {
                    const res = this.$refs.selectRef.handleValidate()
                    if (res) {
                        this.targetKeys = res.targetKeys// 选择的模型ids
                        this.riskControlData = res.dataList// 模型数据
                        let modelBooleanObj = res.modelBooleanObj// 收集所有模型是否选择
                        this.addCommodityParams = {
                            ...addCommodityParams,
                            ...modelBooleanObj
                        }
                        this.step.current = 2
                    }
                }
                    break
                case 2: {
                    const res = this.$refs.configRef.handleValidate()

                    // 提交
                    this.handleSubmit({
                        ...res,
                        ...addCommodityParams
                    })
                }
            }
        },
        /**
         * 获取商品详情
         */
        async getCommodityInfo() {
            const { queryId, getInfoServe } = this
            // 回显
            try {
                this.loading = true
                this.commodityInfo = await getInfoServe({
                    id: queryId
                })
                this.selectRiskCategoryId = this.commodityInfo.selectRiskCategoryId
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 提交
         */
        async handleSubmit(res) {
            const { commitMsg, serviceFn, isCopy } = this
            try {
                this.submitLoading = true
                if (isCopy) {
                    res.id = ''
                }
                await serviceFn(res)
                this.$message.success(commitMsg)
                this.$router.replace({
                    name: 'CommodityList'
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.submitLoading = false
            }
        }

    }
}
</script>

<style lang="scss">
@import '~@/assets/styles/varibles.scss';

.add-commodity {
    &__container {
        overflow-x: scroll;

        &__content {
            min-width: 600px;
        }
    }

    // 按钮group
    &__btn-group {
        margin-top: 30px;
        text-align: center;

        .ant-btn {
            min-width: $custom-button-width;
            margin-right: $custom-button-margin-right;
        }
    }
}
</style>
