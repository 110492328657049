<!-- 商品审核表单 -->
<template>
    <a-form-model
        ref="ruleForm"
        class="rule-approve-form-panel"
        layout="horizontal"
        :label-col="{ span: 5}"
        :wrapper-col="{ span: 16}"
        :model="form"
        :rules="rules"
    >
        <a-form-model-item label="审核意见" prop="auditOpinion">
            <a-input
                v-model="form.auditOpinion"
                placeholder="请输入请输入审核意见"
                type="textarea"
                :max-length="INPUT_AREA_MAX_LENGTH"
                :rows="6" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { FormModel as AFormModel, Input as AInput } from 'ant-design-vue'

import enumOperate from '@mixins/enumOperate'
import auditCommodityService from '@service/auditAndLog/auditCommodityService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_AREA_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'CommodityApproveModalFormPanel',
    components: {
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    mixins: [enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_AREA_MAX_LENGTH,
            form: {
                auditOpinion: ''
            },
            rules: {
                auditOpinion: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入审核意见'
                    }
                ]
            }
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        }
    },
    watch: {},
    methods: {
        handleSubmit(bool) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService(bool)
                }
            })
        },
        async submitService(bool) {
            const { form, id } = this
            const params = {
                id,
                ...form,
                auditState: bool
            }
            try {
                this.$emit('loading', true)
                await auditCommodityService(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>
</style>
