<!-- 评分模型 -->
<template>
    <descriptions-with-head title="评分模型">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:3}"
            :wrapper-col="{ span: 10 }"
            :model="form"
            :rules="rules">
            <a-form-model-item
                label="评分模型"
                prop="scoringModelId">
                <a-select
                    v-model="form.scoringModelId"
                    style="width: 100%"
                    placeholder="请选择"
                    class="select-option-label-prop"
                    option-label-prop="label">
                    <a-select-option
                        v-for="item in dataList"
                        :key="item.id"
                        :value="item.id"
                        :label="item.modelExamplesName">
                        {{ item.modelExamplesName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import { FormModel as AFormModel, Select as ASelect } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import getGradeModelListService from '@service/ruleEngine/GradeModel/getGradeModelListService'
import { DATA_STATE_TYPE_USED, EXPERT_SCORE_CARD_VALUE } from '@constant/enum'

export default {
    name: 'GradeModel',
    components: {
        DescriptionsWithHead,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        // 模型详情数据
        modelInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 分类id
        riskCategoryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataList: [],
            form: {
                scoringModelId: ''
            },
            rules: {
                scoringModelId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        riskCategoryId: {
            handler() {
                this.form.scoringModelId = ''
                this.getList()
            }
        },
        modelInfo: {
            handler(val) {
                const { productControlModel } = val
                if (!productControlModel) return
                this.form.scoringModelId = productControlModel.modelId
            },
            immediate: true
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.form
                }
            })
            return res
        },
        async getList() {
            try {
                this.loading = true
                const { riskCategoryId } = this
                const param = {
                    current: 1,
                    size: 99,
                    riskCategoryId,
                    state: DATA_STATE_TYPE_USED,
                    scoringModelType: EXPERT_SCORE_CARD_VALUE
                }
                const { data } = await getGradeModelListService(param)
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
