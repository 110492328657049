var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"form",staticClass:"fill-commodity-base-info",attrs:{"layout":"horizontal","model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{attrs:{"label":"商品名称","prop":"name"}},[_c('a-input',{attrs:{"type":"text","max-length":_vm.INPUT_MAX_LENGTH,"placeholder":"请输入商品名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"商品描述"}},[_c('a-input',{attrs:{"type":"textarea","max-length":_vm.INPUT_MAX_LENGTH,"placeholder":"请输入内容"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"label":"产品分类","prop":"riskCategoryId","rules":[{
            required: true,
            message: '请选择',
            trigger: 'blur',
        },{validator:_vm.validateCategory}]}},[_c('product-category-cascader',{model:{value:(_vm.form.riskCategoryId),callback:function ($$v) {_vm.$set(_vm.form, "riskCategoryId", $$v)},expression:"form.riskCategoryId"}})],1),(_vm.productParamsData.length)?_vm._l((_vm.productParamsData),function(item,index){return _c('a-form-model-item',{key:item.productSpecId,attrs:{"label":item.productSpecName,"prop":`productParamsData[${index}].attributeId`,"rules":{
                required: item.isRequired,
                message: '请选择',
                trigger: 'blur',
            }}},[_c('a-select',{attrs:{"allow-clear":true},model:{value:(item.attributeId),callback:function ($$v) {_vm.$set(item, "attributeId", $$v)},expression:"item.attributeId"}},_vm._l((item.productSpecValueDTOList),function(type){return _c('a-select-option',{key:type.attributeValueId,attrs:{"value":type.attributeValueId}},[_vm._v(" "+_vm._s(type.attributeValue)+" ")])}),1)],1)}):_vm._e(),_c('a-form-model-item',{attrs:{"label":"订单来源","prop":"orderSource"}},[_c('order-source-select',{model:{value:(_vm.form.orderSource),callback:function ($$v) {_vm.$set(_vm.form, "orderSource", $$v)},expression:"form.orderSource"}})],1),_c('a-form-model-item',{attrs:{"label":"地区范围","prop":"area"}},[_c('area-cascader',{ref:"areaRef",on:{"area-finish":_vm.areaFinish},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('a-button',{staticStyle:{"margin-left":"5px","position":"absolute","top":"-6px"},attrs:{"type":"primary"},on:{"click":_vm.handleAddArea}},[_vm._v(" 添加 ")])],1),_c('a-form-model-item',{attrs:{"wrapper-col":{span: 10, offset: 8}}},[_c('div',{staticClass:"fill-commodity-base-info__area"},_vm._l((_vm.selectAreaData),function(item,index){return _c('a-tag',{key:item.code,attrs:{"closable":""},on:{"close":(e)=>_vm.handleDeleteArea(e,index,item)}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }