import {
    DATA_CHECK_MODEL,
    GRADE_MODEL,
    RISK_ASSESS_MODEL,
    RISK_CLASS_MODEL,
    SMART_ASSESS_MODEL,
    RULE_SET_MODEL
} from '@constant/enum'
// 数据核验
import getDataCheckModelInfoService from '@service/ruleEngine/DataCheckSetting/getDataCheckModelInfoService'
import auditDataCheckService from '@service/auditAndLog/auditDataCheckService'
// 评分模型
import getGradeModelInfoService from '@service/ruleEngine/GradeModel/getGradeModelInfoService'
import auditGradeModelService from '@service/auditAndLog/auditGradeModelService'
// 风险评估
import getRiskAssessmentModelInfoService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentModelInfoService'
import auditRiskAssessService from '@service/auditAndLog/auditRiskAssessService'
// 风险等级
import getRiskClassInfoService from '@service/ruleEngine/riskClass/getRiskClassInfoService'
import auditRiskClassService from '@service/auditAndLog/auditRiskClassService'
// 智能评估
import getSmartAssessModelInfo from '@service/ruleEngine/smartAssessModel/getSmartAssessModelInfoService'
import auditSmartAssessService from '@service/auditAndLog/auditSmartAssessService'

// 规则集
import getRuleSetInfo from '@service/ruleEngine/ruleSet/getRuleInfoService'
import auditRuleSetService from '@service/auditAndLog/auditRuleSetService'
/**
 *      tabsData         模型审核详情页的tab切换
 *          key :tab下的组件名
 *      service          模型审核详情页接口
 *      auditService     模型审核详情页审核接口
 * */
export default {
    [DATA_CHECK_MODEL]: {
        tabsData: [
            {
                key: 'DataCheckBasicInfoTabPanel',
                tab: '基本信息'
            }, {
                key: 'DataCheckLayoutCategoryTabPanel',
                tab: '布局分类'
            }, {
                key: 'DataCheckRiskFactorTabPanel',
                tab: '风险因子'
            }
        ],
        service: getDataCheckModelInfoService,
        auditService: auditDataCheckService
    },
    [GRADE_MODEL]: {
        tabsData: [
            {
                key: 'GradeModelBasicInfoTabPanel',
                tab: '基本信息'
            }, {
                key: 'GradeModelGradeFactorTabPanel',
                tab: '评分因子'
            }
        ],
        service: getGradeModelInfoService,
        auditService: auditGradeModelService
    },
    [RISK_ASSESS_MODEL]: {
        tabsData: [
            {
                key: 'RiskAssessBasicInfoTabPanel',
                tab: '基本信息'
            }, {
                key: 'RiskAssessLayoutCategoryTabPanel',
                tab: '布局分类'
            }, {
                key: 'RiskAssessAssessmentContentTabPanel',
                tab: '评估内容'
            }
        ],
        service: getRiskAssessmentModelInfoService,
        auditService: auditRiskAssessService
    },
    [RISK_CLASS_MODEL]: {
        tabsData: [
            {
                key: 'RiskClassBasicInfoTabPanel',
                tab: '基本信息'
            }
        ],
        service: getRiskClassInfoService,
        auditService: auditRiskClassService
    },
    [SMART_ASSESS_MODEL]: {
        tabsData: [
            {
                key: 'SmartAssessBasicInfoTabPanel',
                tab: '基本信息'
            }
        ],
        service: getSmartAssessModelInfo,
        auditService: auditSmartAssessService
    },
    [RULE_SET_MODEL]: {
        tabsData: [
            {
                key: 'RuleSetBasicInfoTabPanel',
                tab: '基本信息'
            }
        ],
        service: getRuleSetInfo,
        auditService: auditRuleSetService
    }
}
