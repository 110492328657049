<!-- 商品审核弹框 -->
<template>
    <custom-modal
        title="商品审核"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel"
    >
        <template slot="customFooter">
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk(DATA_CHECK_MODEL_AUDIT_STATE_PASS)">通过</a-button>
            <a-button key="reset" @click="handleOk(DATA_CHECK_MODEL_AUDIT_STATE_REFUSE)">驳回</a-button>
        </template>
        <form-panel
            ref="formPanel"
            :info="info"
            @loading="handleLoading"
            @success="handleSuccess"
        />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'
import { DATA_CHECK_MODEL_AUDIT_STATE_REFUSE, DATA_CHECK_MODEL_AUDIT_STATE_PASS } from '@constant/enum'
export default {
    name: 'CommodityApproveModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            DATA_CHECK_MODEL_AUDIT_STATE_REFUSE,
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            loading: false
        }
    },
    computed: {},
    methods: {
        handleLoading(value) {
            this.loading = value
        },
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk(bool) {
            this.$refs.formPanel.handleSubmit(bool)
        },
        handleSuccess() {
            this.loading = false
            this.$emit('success')
        }
    }
}
</script>

<style lang='scss'>
</style>
