<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col :span="6" label="商品名称" prop="name">
            <template slot="formElements">
                <a-input  v-model="form.name" :max-length="INPUT_MAX_LENGTH" placeholder="请输入商品名称" />
            </template>
        </form-col>
        <form-col :span="6" label="产品分类" prop="riskCategoryId">
            <template slot="formElements">
                <product-category-cascader v-model="form.riskCategoryId" />
            </template>
        </form-col>
        <form-col :span="6" label="业务类型" prop="riskBusinessType">
            <template slot="formElements">
                <risk-business-type
                    v-model="form.riskBusinessType" />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'
import RiskBusinessType from '@weights/RiskBusinessType'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'
import { INPUT_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'CommodityApproveListSearchpanel',
    components: {
        AInput,
        SearchPanel,
        FormCol,
        RiskBusinessType,
        ProductCategoryCascader
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                riskCategoryId: '',
                name: '',
                businessType: ''
            }
        }
    },
    methods: {
        handleChange(value) {
            this.form.riskCategoryId = value
        },
        handleSearch() {
            const { form } = this
            this.$emit('search', filterParams(form))
        },
        handleRefresh() {
            const { form } = this
            this.$emit('refresh', filterParams(form))
        }
    }
}
</script>

<style lang="scss">
</style>
