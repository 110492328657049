<!-- 审批及日志-商品审核详情 -->
<template>
    <page-layout
        :show-loading="true"
        :loading="loading"
        class="commodity-approve-info">
        <container-panel>
            <commodity-head
                slot="title"
                :info="info" />
            <a-button
                v-show="showAudit"
                slot="button"
                v-auth="APPROVE_MANAGE_COMMODITY_APPROVE_DETAIL_AUDIT_BUTTON"
                type="primary"
                @click="handleApproveClick"
            >
                审核
            </a-button>
        </container-panel>
        <basic-info :info="info" />
        <risk-control-info :info="info" />
        <commodity-approve-modal :visible="visible" @cancel="handleCancel" @success="handleSuccess" />
    </page-layout>
</template>

<script>
import {
    Button as AButton
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import ContainerPanel from '@components/ContainerPanel'
import CommodityApproveModal from './components/CommodityApproveModal'

import { APPROVE_MANAGE_COMMODITY_APPROVE_DETAIL_AUDIT_BUTTON } from '@constant/authEnum/approve'
import getCommodityInfoService from '@service/auditAndLog/getCommodityInfoService'
import { DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT } from '@constant/enum'

export default {
    name: 'CommodityApproveInfo',
    components: {
        BasicInfo: () => import('@weights/CommodityInfo/BasicInfo'),
        RiskControlInfo: () => import('@weights/CommodityInfo/RiskControlInfo'),
        CommodityHead: () => import('@weights/CommodityInfo/CommodityHead'),
        PageLayout,
        ContainerPanel,
        AButton,
        CommodityApproveModal
    },
    data() {
        return {
            title: '保前风控业务合同履约险商品',
            APPROVE_MANAGE_COMMODITY_APPROVE_DETAIL_AUDIT_BUTTON,
            avatar:
          'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
            visible: false,
            loading: false,
            info: {}
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id
        },
        showAudit() {
            const { info } = this
            return info.auditState === DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
    /**
     * tab改变
     */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
     * 审核
     */
        handleApproveClick() {
            this.visible = true
        },
        /**
     * 关闭弹框
     */
        handleCancel() {
            this.visible = false
        },
        handleSuccess() {
            this.visible = false
            this.getInfo()
        },
        /**
     * 获取商品详情
     * */
        async getInfo() {
            try {
                this.loading = true
                const { queryId } = this
                this.info = await getCommodityInfoService({
                    id: queryId
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
                this.loading = false
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.commodity-approve-info {
  .ant-card-head-title {
    padding: 16px 0 0 0;
  }
}
</style>
